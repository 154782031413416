import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({spacing, breakpoints, palette}) => ({
  container: {
    display: 'flex',
    gap: spacing(6),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '& > div:first-child': {
      flex: 1,
      padding: spacing(2),
    },
    '& > div:last-child': {
      margin: 'auto',
      flex: 1,
      padding: spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      placeItems: 'flex-end',
    },
    '& > div:last-child img': {
      maxHeight: 300,
    },
  },

  wrapper: {
    alignItems: 'center',
  },

  cardGrid: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',

    [breakpoints.down('sm')]: {
      height: '90%',
      overflow: 'scroll',
      display: 'flex',
      alignItems: 'center',
    },
  },
  card: {
    lineHeight: spacing(4),
    border: `1px solid ${palette.background.contrastGrey}`,
    fontFamily: 'CamptonLight',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: spacing(4),
    padding: spacing(3),
    boxShadow: 'none',
    '& div:nth-child(0)': {
      minHeight: 200,
    },
    '& img': {
      borderRadius: 4,
      objectFit: 'cover',
      height: 100,
      [breakpoints.down('sm')]: {
        marginTop: spacing(4),
        width: '100%',
      },
    },
    [breakpoints.up('sm')]: {
      height: 600,
    },
    [breakpoints.down('md')]: {
      alignContent: 'center',
      height: '90%',
      display: 'flex',
      justifyContent: 'center',
    },
  },

  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: 'white',
    backgroundColor: palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
      backgroundColor: palette.primary.main,
    },
  },

  itemsContainer: {
    margin: 'auto',
    display: 'flex',
    gap: spacing(3),
    '& h3': {
      marginBottom: spacing(1),
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      display: 'block',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'scroll',
      margin: 'auto',
    },
  },

  media: {
    '& img': {
      maxWidth: 260,
      maxHeight: 160,
      margin: 'auto',
    },
  },

  itemContainer: {
    maxWidth: 300,
  },
  mediaContainer: {
    justifyContent: 'center',
    display: 'flex',
  },
  modalContainer: {
    flex: 1,
    height: 'auto',
  },
}))

export default useStyles
