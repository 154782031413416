import * as R from 'ramda'
import {Card, Grid} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import React, {useState} from 'react'

import Description from 'components/UI/Description'
import Media from 'components/UI/Media'
import RoundButton from 'components/UI/RoundButton'
import Title from 'components/UI/Title'

import useStyles from './styles'

const SimpleModal = ({content, items, stepByStepSectionContent}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const primaryText = R.pathOr(
    '',
    ['primaryText', 'primaryText'],
    stepByStepSectionContent,
  )
  const secondaryText = R.pathOr(
    '',
    ['secondaryText', 'secondaryText'],
    stepByStepSectionContent,
  )

  const cta = R.pathOr('', ['cta'], stepByStepSectionContent)

  const hat = R.pathOr('', ['primaryText', 'primaryText'], content)
  const title = R.pathOr('', ['secondaryText', 'secondaryText'], content)

  const body = (
    <Grid item xs={12} sm={6} md={10} className={classes.cardGrid}>
      <Card className={classes.card}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large">
          <CloseIcon />
        </IconButton>

        <Title isCentered variant="h3" type="largeTitle" hat={hat}>
          {title}
        </Title>

        <div className={classes.itemsContainer}>
          {items?.map(item => {
            const itemTitle = R.pathOr('', ['primaryText', 'primaryText'], item)
            const itemDescription = R.pathOr(
              '',
              ['secondaryText', 'secondaryText'],
              item,
            )

            return (
              <div className={classes.itemContainer}>
                <div className={classes.mediaContainer}>
                  <Media className={classes.media} data={item.image} />
                </div>
                <Title
                  hasSmallPaddingBottom
                  isCentered
                  variant="h3"
                  type="innerTitle"
                >
                  {itemTitle}
                </Title>
                <Description isCentered>{itemDescription}</Description>
              </div>
            )
          })}
        </div>
        <Description hasMarginTop type="description" isCentered>
          {content.cta}
        </Description>
      </Card>
    </Grid>
  )

  return (
    <div className={classes.container}>
      <div>
        <Title>{primaryText}</Title>
        <Description type="description">{secondaryText}</Description>
      </div>

      <div className={classes.modalContainer}>
        <div className={classes.wrapper}>
          <RoundButton
            color="primary"
            arrow
            action={handleOpen}
            isAction
            isCentered
          >
            {cta}
          </RoundButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default SimpleModal
